/* Company.css */
.company-section {
  padding: 150px 20px;
  background-color: #202c45;
  text-align: center;
}

.company-title {
  font-size: 2.5em;
  margin-bottom: 40px;
  color: #ffffff;
}

/* Wrapper to ensure grid alignment */
.company-grid-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: center; /* Center the grid within its container */
}

.company-grid {
  display: flex;
  justify-content: center; /* Center the grid items horizontally */
  flex-wrap: wrap; /* Ensure items wrap to new lines as needed */
}

.grid-item {
  display: flex;
  justify-content: center; /* Center each grid item */
}

.card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s, box-shadow 0.3s;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 350px; /* Fixed height for consistency */
  width: 100%; /* Ensure card takes up full space in grid */
  max-width: 280px; /* Limit width to ensure consistency */
}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  /* Ease the transition in and out on hover */
  transition: 0.3s ease-in-out;
}

.team-card-image {
  border-radius: 15px 15px 0 0;
}

.team-card-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.team-card-name {
  font-size: 1.2em;
  margin-top: 10px;
  color: #333333; /* Darker color for readability on white */
  font-weight: bold;
}

.team-card-position {
  font-size: 0.9em;
  color: #666666; /* Subtle gray for secondary text */
  margin: 5px 0;
}

/* CTA Card Styling */
.cta-card:hover {
  transform: none;
}

.cta-card-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20px;
}

.cta-card-title {
  font-size: 1.5em;
  margin-bottom: 20px;
  color: #333333; /* Ensure visibility on white background */
  text-align: center;
}

.cta-card .cta-card-button {
  background-color: #88e0c3; /* Green accent color */
  color: #202c45; /* Dark text for contrast */
  padding: 10px 20px;
  border-radius: 25px; /* Rounded corners */
  text-transform: none; /* Disable uppercase transformation */
  transition: background-color 0.3s, transform 0.3s;
  font-weight: 600;
  font-size: 1em;
  min-width: 120px; /* Ensure the button has a consistent width */
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add some shadow for depth */
}

.cta-card-button-link {
  /* Remove default link styling */
  color: inherit;
  text-decoration: none;
}

.cta-card .cta-card-button:hover {
  background-color: #76cbb3; /* Darker shade on hover */
  transform: translateY(-2px); /* Slight hover lift */
}

/* Ensure the button icon is aligned properly */
.cta-card-button .MuiButton-endIcon {
  margin-left: 8px; /* Space between text and icon */
}

@media (max-width: 768px) {
  .company-title {
    font-size: 2em;
  }

  .card {
    margin-bottom: 20px;
  }
}
