.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  background-color: transparent;
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px; /* Set a max-width for centered content */
  margin: 0 auto; /* Center the container */
}

.header-main-text a {
  font-size: 2.5rem;
  color: #4dec4d;
  font-weight: bold;
  margin: 0;
  text-decoration: none;
  text-transform: uppercase;
}

nav {
  display: flex;
  align-items: center;
  background-color: #303c53;
  padding: 10px 20px;
  border-radius: 50px;
}

nav a {
  text-decoration: none;
  color: #ffffff;
  margin: 0 15px;
  font-size: 1.2rem;
  transition: color 0.3s;
}

nav a.active-link {
  color: #4dec4d;
}

nav a:hover {
  color: #88e0c3;
}

@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: center;
    padding: 15px 20px;
  }

  .header-main-text {
    font-size: 2rem;
    margin-bottom: 10px;
  }

  .header-container {
    flex-direction: column;
    align-items: center;
  }

  nav {
    justify-content: center;
    margin-top: 10px;
    padding: 8px 15px;
    border-radius: 30px;
    margin-bottom: 20px;
  }

  nav a {
    margin: 0 10px;
    font-size: 1rem;
  }

  .signup-button {
    background-color: #4dec4d;
    color: #202c45 !important;
    font-size: 0.9rem;
    padding: 8px 15px;
    border-radius: 50px;
    margin-top: 10px;
  }

  .signup-button-icon {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .header {
    padding: 10px;
  }

  .header-main-text {
    font-size: 1.8rem;
  }

  nav a {
    font-size: 0.9rem;
    margin: 0 8px;
  }

  .signup-button {
    font-size: 0.8rem;
    padding: 7px 12px;
  }

  .signup-button-icon {
    font-size: 0.8rem;
  }
}
