/* Import Inter font. */
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

/* Define custom properties for colors */
:root {
  --primary-font: 'Inter', sans-serif;
  --primary-color: #202c45;
}

/* General styles */
body {
  font-family: var(--primary-font);
  background-color: var(--primary-color);
}

* {
  margin: 0;
  padding: 0;
}

.test-1 {
  position: relative; width: 100%; height: 0; padding-top: 56.2225%;
  padding-bottom: 0; box-shadow: 0 2px 8px 0 rgba(63,69,81,0.16); margin-top: 1.6em; margin-bottom: 0.9em; overflow: hidden;
  border-radius: 8px; will-change: transform;
}

.test-1 iframe {
  position: absolute; width: 100%; height: 100%; top: 0; left: 0; border: none; padding: 0;margin: 0;
}



.font-color-primary {
  color: rgb(77, 236, 109);

}