.signup-button {
    background-color: #4dec4d !important;
    color: #202c45 !important;
    padding: 10px 20px;
    border-radius: 50px !important;
    text-transform: none;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s;
}

.signup-button a {
    text-decoration: none;
    color: #202c45 !important;
}

.signup-button a:visited {
    color: #202c45 !important;
}

.signup-button-icon {
    margin-left: 8px;
    font-size: 1rem;
}
